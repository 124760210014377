.search-popover {
  .content {
    @apply absolute z-10 hidden w-56 rounded bg-white py-4 shadow-2xl;
  }

  .caret {
    @apply ml-2 text-brand-blue;
  }

  .trigger-button {
    @apply button button-flex button-dropdown button-sm items-center border border-transparent;
  }

  .min-max-inputs-stacked {
    label {
      @apply text-sm text-slate-600;
    }
    .input-item {
      @apply mt-1;
    }
    .input-item:first-child {
      @apply mt-0;
    }
  }

  &.active {
    .trigger-button {
      @apply border border-brand-blue bg-brand-blue bg-opacity-20;
    }
  }

  &.open {
    .trigger-button {
      @apply border border-brand-blue bg-white;
    }

    .caret {
      transform: rotate(180deg);
    }

    .content {
      @apply block;
    }
  }
}
