.deals-table {
  @apply mt-8 border-separate;
  border-spacing: 0 20px;

  tr td {
    @apply border-t border-b border-gray-100 text-neutral-700;
  }

  tr td:first-child {
    @apply rounded-tl-2xl rounded-bl-2xl border-gray-100;
  }
  tr td:last-child {
    @apply rounded-tr-2xl rounded-br-2xl border-gray-100;
  }

  th,
  td {
    @apply p-3 align-middle;
  }

  thead {
    th {
      @apply relative border-0 pb-4 text-xl font-medium;
    }
    .header-name {
      @apply flex items-center whitespace-nowrap text-lg text-neutral-700;
      img {
        @apply w-5 shrink-0 pl-3;
      }
    }
    .header-name.deal-name {
      img {
        @apply w-9;
      }
    }
    .header-search-input {
      min-width: 200px;
      @apply absolute hidden rounded border border-neutral-200 bg-white p-5 text-left shadow;
      .sort-link {
        @apply pt-3;
        a {
          @apply text-base text-gray-700 underline;
        }
      }
    }
  }
  tbody {
    .deal-name {
      @apply font-medium;
    }
    .industry-label {
    }
    tr {
      @apply bg-white;
      h3 {
        @apply mb-0 text-gray-400;
      }
    }
  }
}
