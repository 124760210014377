.chosen-container {
  .chosen-choices {
    @apply text-input-1 py-2 px-3 text-base;
    li.search-choice {
      @apply static flex items-center bg-neutral-200 py-1 px-2 text-sm font-medium;
      .search-choice-close {
        @apply static ml-1;
      }
    }
  }
  .chosen-drop {
    @apply rounded-b-lg border border-neutral-300 text-base shadow;
  }
}

.chosen-container-single {
  .chosen-drop {
    .chosen-search {
      @apply my-2 pr-4 pl-2;
      input {
        @apply text-input-1 py-1 px-3;
      }
    }
  }
  .chosen-results {
    .active-result {
      &.highlighted {
        @apply bg-brand-blue;
      }
    }
  }
  .chosen-single {
    div {
      top: 6px;
    }
    &.chosen-single-with-deselect {
      @apply text-input-1 flex h-10 items-center justify-between py-1 px-3 text-base;
      line-height: unset;
      .search-choice-close {
        top: 12px;
      }
    }
    &.chosen-default {
      @apply select-input-1 flex h-10 items-center justify-between py-1 px-3 text-base;
    }
  }
}

.chosen-container-multi {
  .chosen-results {
    .active-result {
      &.highlighted {
        @apply bg-brand-blue;
      }
    }
  }
}
