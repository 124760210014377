@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./app/chosen.scss";
@import "./app/chosen_overrides.scss";
@import "./app/pagination.scss";
@import "./app/deals_table.scss";
@import "./app/search_popover.scss";
@import "./app/directory.scss";
@import "./app/plan_picker.scss";
@import "./app/terms_privacy_page.scss";
@import "./app/landing_pages.scss";
@import "./app/sparkline.scss";

/* These overrides can be placed in a separate CSS file */
.markdown-light {
  @apply bg-gray-100 text-gray-800;
}

.markdown-dark {
  @apply bg-teal-600 text-white;
}

.markdown-dark .markdown,
.markdown-light .markdown {
  /* Adjust spacing for chat bubbles */
  --markdown-block-spacing: 0.5rem;

  & p {
    @apply mb-2;
  }

  & p:last-child {
    @apply mb-0;
  }

  & h1,
  & h2,
  & h3,
  & h4 {
    @apply mt-3 mb-2;
  }

  ol {
    @apply list-decimal;
  }

  ul {
    @apply list-disc;
  }

  & ul,
  & ol {
    @apply ml-8 mb-2;
  }

  & li {
    @apply mb-1;
  }

  & pre,
  & code {
    @apply bg-black bg-opacity-10;
  }

  & blockquote {
    @apply border-current;
  }

  & a {
    @apply text-current hover:text-current;
  }

  & img {
    @apply my-2;
  }

  & hr {
    @apply my-4;
  }
}

body {
  font-variant-ligatures: none;
  --sl-color-primary-600: #1fc3cc;
}

sl-tab-group.indigo {
  --indicator-color: #4f46e5;
  --sl-color-primary-600: #4f46e5;
}

sl-checkbox {
  --sl-input-border-color: #1fc3cc;
}

sl-checkbox[size="medium"] {
  --sl-input-border-width: 2px;
}

sl-checkbox::part(checked-icon),
sl-checkbox::part(checked-icon):hover {
  --sl-input-background-color: #1fc3cc;
}

/* tippy.css or maybe chosen? was adding some styles to inputs */
sl-input[type="text"] {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border: none;
}

sl-checkbox::part(control) {
  @apply rounded;
}

sl-dialog.dialog-position-top-1::part(base) {
  align-items: baseline;
  margin-top: 100px;
}

sl-dialog.dialog-max-w-800::part(panel) {
  max-width: 800px;
  width: 90vw;
  @media (min-width: 768px) {
    width: 80vw;
  }
  @media (min-width: 1024px) {
    width: 50vw;
  }
}

sl-dialog.dialog-max-w-500::part(panel) {
  max-width: 500px;
  width: 90vw;
}

sl-dialog.dialog-overlay-dark-1 {
  --sl-overlay-background-color: rgba(113, 113, 122, 0.9);
}

sl-details.inline-details-toggle {
  display: inline-block;
}

sl-details.inline-details-toggle::part(base) {
  border: none;
  background: inherit;
}

sl-details.inline-details-toggle::part(header) {
  align-items: center;
  padding: 0;
  @apply text-gray-600;
}

sl-details.inline-details-toggle::part(content) {
  @apply px-0 pt-2 pb-0;
}

@layer utilities {
  .spacer {
    @apply grow;
  }

  .blurred {
    text-decoration: none;
    color: transparent;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  }

  .max-container {
    @apply mx-auto max-w-screen-xl px-4;
  }

  .scrollbar-gutter-stable {
    scrollbar-gutter: stable;
  }
}

@layer components {
  .text-input-1 {
    @apply mt-1 block w-full cursor-pointer rounded-md border-gray-300 placeholder-gray-400 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50;
  }

  .text-input-2 {
    @apply block w-full cursor-pointer rounded-lg border-slate-200 px-3 py-2.5 rounded-lg border-solid w-full placeholder:text-slate-400 placeholder:text-slate-400 border border-slate-200 focus:border-brand-blue focus:ring-1 focus:ring-brand-blue focus:ring-opacity-50;
  }

  .select-input-1 {
    @apply mt-1 block w-full cursor-pointer rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50;
  }

  .checkbox-1 {
    @apply ml-2;
  }

  .button {
    @apply inline-block cursor-pointer whitespace-nowrap rounded font-medium;
  }

  .button-flex {
    @apply flex items-center space-x-2;
  }

  .button-inline-flex {
    @apply inline-flex items-center space-x-2 justify-between;
  }

  .button-xs {
    @apply px-2.5 py-1.5 text-xs;
  }

  .button-sm {
    @apply px-3 py-2 text-sm;
  }

  .button-md {
    @apply px-4 py-2 text-sm;
  }

  .button-lg {
    @apply px-4 py-2;
  }

  .button-xl {
    @apply px-6 py-3 text-lg;
  }

  .button-2xl {
    @apply rounded-lg px-8 py-4 text-2xl;
  }

  .button-gray {
    @apply border border-neutral-300 bg-neutral-200 bg-opacity-70 text-neutral-500 transition-colors ease-in hover:bg-opacity-100;
  }

  .button-black {
    @apply bg-neutral-900 bg-opacity-70 text-white transition-colors ease-in hover:bg-opacity-100;
  }

  .button-outline {
    @apply border border-gray-900 bg-white text-gray-900;
  }

  .button-light-blue {
    color: #0e7490;
    background: #e0f2fe;
  }

  .button-brand {
    @apply bg-brand-blue text-white;
  }

  .button-dropdown {
    @apply bg-slate-200 text-slate-900;
  }

  .button-brand-outline {
    @apply border border-brand-blue bg-white text-brand-blue transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-100 hover:bg-brand-blue hover:text-white;
  }

  .button-red {
    @apply border border-red-800 bg-red-700 bg-opacity-70 text-white transition-colors ease-in hover:bg-opacity-100;
  }

  .button-yellow {
    @apply border border-yellow-500 bg-yellow-400 text-gray-900 transition-colors ease-in hover:bg-opacity-100;
  }

  .button-shadow {
    @apply shadow-brand-button;
  }

  .alert {
    @apply max-w-xl rounded py-4 px-5;

    h1 {
      @apply text-lg font-bold;
    }

    p {
      @apply text-slate-600;
    }
  }

  .alert-info,
  .alert-secondary {
    @apply bg-blue-400 bg-opacity-20;
  }

  .alert-warning {
    @apply bg-yellow-400 bg-opacity-20;
  }

  .alert-error {
    @apply bg-red-400 bg-opacity-20;
  }

  .min-max-inputs {
    @apply flex items-center;
    span {
      @apply mx-5 text-gray-400;
    }
  }

  .toggle-checkbox,
  .toggle-checkbox:focus,
  .toggle-checkbox:hover {
    --tw-ring-offset-width: 1px;
    --tw-ring-offset-shadow: none;
    --tw-ring-shadow: none;

    @apply border-neutral-300 p-0;
    @apply absolute block h-6 w-6 cursor-pointer appearance-none rounded-full border-4 bg-white;
  }

  .toggle-checkbox:checked,
  .toggle-checkbox:checked:focus,
  .toggle-checkbox:checked:hover {
    @apply border border-neutral-300 bg-none text-white;
  }

  .toggle-checkbox:checked {
    @apply right-0;
  }
  .toggle-checkbox:checked + .toggle-label {
    @apply border border-brand-blue bg-brand-blue;
  }

  .current-filters-alert {
    @apply flex items-center rounded-xl bg-brand-blue bg-opacity-20 py-5 px-6;
  }

  .landing-page-hype-video-1 {
    --media-control-background: transparent;
    --media-control-hover-background: transparent;
    @apply text-black;
    media-loading-indicator {
      --media-icon-color: #1fc3cc;
    }
  }
}

.bubbly-ball-bg {
  @media (min-width: 768px) {
    background-image: url("bubbly-ball@2x.png");
    background-position: 110% -18rem;
  }
  @media (min-width: 1024px) {
    background-position: 100% -4rem;
    background-size: 44%;
  }
  @media (min-width: 1100px) {
    background-position-y: -7rem;
  }
  @media (min-width: 1200px) {
    background-position-y: -8rem;
  }
  @media (min-width: 1300px) {
    background-position-y: -12rem;
  }
  @media (min-width: 1400px) {
    background-position-y: -18rem;
  }
  @media (min-width: 1500px) {
  }
  @media (min-width: 1600px) {
    background-position-y: -24rem;
  }
  @media (min-width: 1700px) {
    background-position-y: -26rem;
  }
  @media (min-width: 1800px) {
    background-position-y: -32rem;
  }
  @media (min-width: 1900px) {
  }
  @media (min-width: 2000px) {
    background-position-y: -44rem;
  }
  @media (min-width: 2100px) {
  }
  @media (min-width: 2200px) {
    background-position-y: -60rem;
  }
}

.blue-fade-network-bg-fade {
  background-image: linear-gradient(
      to bottom,
      rgba(107, 211, 217, 1),
      rgba(242, 248, 251, 1)
    ),
    linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  @media (min-width: 1280px) {
  }
  &.extra-fade {
    background-image: linear-gradient(
        to bottom,
        rgba(107, 211, 217, 0.8),
        rgba(242, 248, 251, 0.8)
      ),
      linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
}

.blue-fade-network-bg-image {
  background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    ),
    /* linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5)), more opacity */
      url("partnersource-bg-1.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  @media (min-width: 1280px) {
  }
  &.extra-fade {
    background-image: linear-gradient(
        to top,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.5)
      ),
      url("partnersource-bg-1.png");
  }
}

.buyer-search-top {
  background: linear-gradient(180deg, #d8fdff -93.28%, #f2fafe 96.01%),
    radial-gradient(
        37.5% 96.07% at 18.27% -26.83%,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.item-details-drawer {
  --size: 90%;
  @media (min-width: 640px) {
    --size: 66%;
  }
  @media (min-width: 768px) {
    --size: 50%;
  }
}

.item-details-drawer::part(footer) {
  @apply border-t-2 border-gray-200 border-solid border-r-0 border-l-0 border-b-0;
}
