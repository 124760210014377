.pagination {
  @apply mt-2 rounded border border-gray-400 py-2 px-4 text-neutral-500;
  .page,
  .next,
  .last,
  .first,
  .prev {
    @apply px-2;
  }
  .page {
    @apply inline-block text-center;
  }
  .page.current {
    @apply font-bold underline underline-offset-4;
  }
}
