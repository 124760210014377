.terms-priv-page {
  h1 {
    @apply text-3xl font-bold;
  }

  h2 {
    @apply text-2xl font-bold;
  }

  p {
    @apply my-4;
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }

  ul,
  ol {
    @apply ml-8;

    li {
      @apply my-2;
    }
  }
}
