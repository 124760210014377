@layer components {
  .sparkline-label {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 2px 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 9999;
  }

  .sparkline--cursor {
    stroke: orange;
  }

  .sparkline--spot {
    fill: red;
    stroke: red;
  }
}
